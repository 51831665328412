export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "pagetitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn autobedrijf"])},
        "maintitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactmogelijkheden van uw autobedrijf"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
        "algemeenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen opgeslagen"])},
        "optinAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorelevante informatie"])},
        "optinMerkModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk-/modelrelevante informatie"])},
        "optinBedrijfsrelevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsrelevante informatie"])},
        "optinOverigrelevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overige (commerciële) informatie"])},
        "optinEnquete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantentevredenheidsonderzoek"])},
        "nietAutomatischBenaderen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik wil niks ontvangen"])},
        "perEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
        "perSMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaging"])}
      },
      "fr": {
        "deeznuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicules"])}
      },
      "de": {
        "pagetitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Autohaus"])},
        "maintitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktmöglichkeiten Ihren Autohaus"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "algemeenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen gespeichert"])},
        "optinAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-Informationen"])},
        "optinMerkModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marke-/Modellinformationen"])},
        "optinBedrijfsrelevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensinformationen"])},
        "optinOverigrelevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige (vertriebliche) Informationen"])},
        "optinEnquete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenzufriedenheitsumfrage"])},
        "nietAutomatischBenaderen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte nichts erhalten"])},
        "perEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "perSMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaging"])}
      }
    }
  })
}