export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "gerechtvaardigdBelangVerklaring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autobedrijven zullen je nu enkel nog maar benaderen wanneer dat noodzakelijk is voor de uitvoering van hun dienstverlening."])}
      },
      "fr": {
        "gerechtvaardigdBelangVerklaring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicules"])}
      },
      "de": {
        "gerechtvaardigdBelangVerklaring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobilunternehmen werden nur noch dann auf Sie zukommen, wenn dies für die Erbringung ihrer Dienstleistungen erforderlich ist."])}
      }
    }
  })
}